<template>
  <div>
    <div v-if="!showQr" class="row">
      <div class="col-12 mt-5">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">Search</h4>
            <form>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="example-date-input" class="col-form-label">Start date</label>
                  <input v-model="start_date" class="form-control" type="date">
                </div>
                <div class="col-md-6 mb-3">
                  <label for="example-date-input" class="col-form-label">End date</label>
                  <input v-model="end_date" class="form-control" type="date">
                </div>
              </div>
              <div v-if="search_location !== '/dashboard/qr'" class="form-group">
                <label for="desc" class="col-form-label">Description</label>
                <textarea v-model="description" class="form-control" id="desc"></textarea>
              </div>
              <button v-if="search_location === '/dashboard/qr'" @click.prevent="search" class="btn btn-primary" type="submit">Generate Qr</button>
              <button v-else @click.prevent="search" class="btn btn-primary" type="submit">Search</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <QrDetails @goback="showQr = false" v-else-if="showQr" />
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
import QrDetails from "@/components/Dashboard/QrDetails.vue";
export default {
  components: {
    QrDetails
  },
  data() {
    return {
      start_date: "",
      end_date: "",
      description: "",
      search_location: this.$route.path,
      showQr: false,
      isLoading: false
    };
  },

  mounted() {
    console.log("path: ", this.$route.path);
  },

  methods: {
    search() {
      let action;
      if (this.search_location === "/dashboard/finance/voucher/list") {
        action = "finance/searchVouchers";
      } else if (this.search_location === "/dashboard/finance/income/income-list") {
        action = "finance/searchIncomes";
      } else if (this.search_location === "/dashboard/qr") {
        action = "admin/generateQr";
        this.isLoading = true
      }
      this.$store.dispatch(action, {
          start_date: this.start_date,
          end_date: this.end_date,
          description: this.description
        })
        .then((err) => {
          if (err) {
            this.isLoading = false;
            console.log(err);
          } else {
            this.start_date = "";
            this.end_date = "";
            this.description = "";
            console.log("it works");
            if (action === "admin/generateQr") {
              this.isLoading = false;
              this.showQr = true;
              console.log("beans")
            }
          }
        })
    }
  }
};

</script>
