<template>
  <div>
    <Search />
    <div class="row">
      <div class="col-lg-12 mt-5">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">Income</h4>
            <div class="single-table">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead class="text-uppercase bg-success">
                    <tr class="text-white">
                      <th scope="col">description</th>
                      <th scope="col">amount</th>
                      <th scope="col">date</th>
                      <th scope="col">action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="income in incomes" :key="income.id">
                      <th scope="row">{{ income.description }}</th>
                      <td>
                        {{ income.amount }}
                      </td>
                      <td>{{ income.transaction_date | dateFilter }}</td>
                      <td><i @click="deleteUser(user.id)" class="ti-trash text-danger"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import Search from "@/components/Dashboard/Search.vue";
export default {
  layout: "dashboard",
  components: {
    Search
  },
  computed: {
    incomes() {
      return this.$store.getters["finance/getIncomes"];
    }
  },

  methods: {
    deleteService(serviceId) {
      this.showQuestion().then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteService", serviceId).then(() => {
            Swal.fire(
              "Deleted!",
              "Service has been deleted.",
              "success"
            );
          }).catch((e) => {
            console.log(e);
            this.showError("Service delete unsuccessful");
          });
        }
      });
    }
  },

  mounted() {
    this.$store.dispatch("finance/getIncomes");
    console.log("bread");
  }
};
</script>
<style scoped>
.sn {
  font-size: 14px !important;
}
</style>