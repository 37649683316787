<template>
  <div>
    <div class="btn-flex mt-5">
      <button @click="print" class="btn btn-info">Print</button>
      <button v-if="approved === null" @click="approve" class="btn btn-info">Approve</button>
      <button v-else-if="approved === 1" class="btn btn-success">Approved</button>
    </div>
    <div class="row">
      <div class="col-12 mt-5">
        <div id="printMe" class="card">
          <div class="card-body">
            <h4 class="header-title">Payroll</h4>
            <div class="single-table">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead class="text-uppercase bg-success">
                    <tr class="text-white">
                      <th scope="col">name</th>
                      <th scope="col">net salary</th>
                      <th scope="col">salary</th>
                      <th scope="col">staff no</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="staff in payroll" :key="staff.staff_id">
                      <th scope="row">{{ staff.name }}</th>
                      <td>
                        {{ staff.netSalary }}
                      </td>
                      <td>{{ staff.salary }}</td>
                      <td>{{ staff.staff_id }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      approved: null
    }
  },
  computed: {
    payroll() {
      return this.$store.getters["finance/getSinglePayroll"](this.$route.params.id);
    },

    payroll_approved() {
      return this.$store.getters["finance/getPayrollApproval"](this.$route.params.id);
    }
  },

  methods: {
    deleteService(serviceId) {
      this.showQuestion().then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteService", serviceId).then(() => {
            Swal.fire(
              "Deleted!",
              "Service has been deleted.",
              "success"
            );
          }).catch((e) => {
            console.log(e);
            this.showError("Service delete unsuccessful");
          });
        }
      });
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    approve() {
      this.$store.dispatch("finance/approvePayroll", {
          staffId: "BST/CR/001",
          payrollId: this.$route.params.id
        })
        .then((err) => {
          if (err) {
            console.log(err);
            this.$swal({
              position: "center",
              icon: "error",
              title: "Error",
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Payroll Approved",
              showConfirmButton: false,
              timer: 1500
            })
            this.approved = 1;
            console.log("it works");
          }
        });
    }
  },

  fetch() {
    this.$store.dispatch("finance/getPayroll")
      .then((err) => {
        if (err) {
          console.log(err);
        } else {
          console.log("payro says: ", this.payroll);
          console.log("id says: ", this.$route.params.id);
          this.approved = this.payroll_approved;
        }
      })
    console.log("bread");
  }
};
</script>
<style scoped>
.sn {
  font-size: 14px !important;
}

.btn-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>