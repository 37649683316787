<template>
  <div class="col-12 mt-5">
    <div class="card">
      <div class="card-body">
        <h4 class="header-title">Fund user wallet</h4>
        <form>
          <div class="form-group">
            <label for="example-email-input" class="col-form-label">Email</label>
            <input v-model="email" class="form-control" type="email"  id="example-email-input">
          </div>
          <div class="form-group">
            <label for="example-number-input" class="col-form-label">Amount</label>
            <input v-model="amount" class="form-control" type="number" id="example-number-input">
          </div>
          <button @click.prevent="submit" class="btn btn-primary" type="submit">Submit</button>
        </form>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
      amount: null,
      isLoading: false
    }
  },

  methods: {
    submit() {
      this.isLoading = true;
      this.$store.dispatch("finance/fundUserWallet", {
          user_email: this.email,
          amount: +this.amount,
          staffId: "BST/CRS/001"
        })
        .then((data) => {
          this.isLoading = false;
          this.$swal({
            position: "center",
            icon: "success",
            title: data.data,
            showConfirmButton: false,
            timer: 1500
          });
          this.email = null;
          this.amount = null;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error",
            showConfirmButton: false,
            timer: 1500
          });;
        })
    }
  }
}
</script>