<template>
  <div class="row">
    <div class="col-12 mt-5">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Create Income</h4>
          <form>
            <div class="form-group">
              <label for="example-text-input" class="col-form-label">Description</label>
              <input v-model="item.description" class="form-control" type="text" id="example-text-input">
            </div>
            <div class="form-group">
              <label for="example-number-input" class="col-form-label">Amount</label>
              <input v-model="item.amount" class="form-control" type="number" id="example-number-input">
            </div>
            <button @click.prevent="submit" class="btn btn-primary" type="submit">Submit</button>
          </form>
        </div>
      </div>
      <!-- <div class="card">
      <div class="card-header">
        <strong class="card-title">Create Income</strong>
      </div>
      <div class="card-body">
        <div id="pay-invoice">
          <div class="card-body">
            <form action="#" method="post" novalidate="novalidate">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="cc-payment" class="control-label mb-1">Description</label>
                    <input v-model="item.description" id="cc-payment" name="cc-desc" type="text" class="form-control" aria-required="true" aria-invalid="false" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <label for="x_card_code" class="control-label mb-1">Amount</label>
                  <div class="input-group">
                    <input v-model="item.amount" id="x_card_code" name="x_card_code" type="number" class="form-control cc-cvc" value="" autocomplete="off">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button @click.prevent="submit" id="payment-button" type="submit" class="btn btn-lg btn-info btn-block mt-3">
                    <span id="payment-button-amount">Done</span>
                    <span id="payment-button-sending" style="display:none;">Sending…</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
      <Spinner v-if="isLoading" />
    </div>
    <!--/.col-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: {
        description: "",
        amount: ""
      },
      isLoading: false
    }
  },

  methods: {
    submit() {
      console.log("items: ", this.items);
      console.log("amount: ", this.item.amount);
      console.log("desc: ", this.item.description);
      this.isLoading = true;
      this.$store.dispatch("finance/createIncome", {
          staffId: "BST/CR/009",
          amount: this.item.amount,
          description: this.item.description
        })
        .then((data) => {
          this.isLoading = false;
          console.log("create income data: ", data.data);
          console.log("works");
          this.$swal({
            position: "center",
            icon: "success",
            title: data.data,
            showConfirmButton: false,
            timer: 1500
          })
          this.item.description = "";
          this.item.amount = "";
        })
        .catch((err) => {
          this.isLoading = false;
          this.$swal({
            position: "center",
            icon: "success",
            title: err.response.data,
            showConfirmButton: false,
            timer: 1500
          })
          console.log(err);
        });
    }
  }
}
</script>
<style scoped>
#payment-button-amount {
  font-size: 15px !important;
}
</style>