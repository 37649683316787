<template>
  <div>
    <div class="container d-flex mt-5 justify-content-between align-items-center">
      <div>
        <button @click="print" class="btn btn-success">Print</button>
      </div>
      <div class="text-right">
        <i @click="$emit('goback')" class="ti-close text-danger"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-5">
      <div id="printMe" class="card">
        <div class="card-body">
          <h4 class="header-title">Sign in and sign out</h4>
          <div class="single-table">
            <div class="table-responsive">
              <table class="table text-center">
                <thead class="text-uppercase bg-success">
                  <tr class="text-white">
                    <th scope="col">date</th>
                    <th scope="col">sign in</th>
                    <th scope="col">sign out</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(qr, index) in qrDetails" :key="index">
                    <th class="text-left" scope="row">{{ qr.date }}</th>
                    <td>
                      <img :src="qr.qrcode.sign_in" class="img-fluid">
                    </td>
                    <td><img :src="qr.qrcode.sign_out" class="img-fluid"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    qrDetails() {
      return this.$store.getters["admin/getQrDetails"];
    }
  },

  methods: {
    print() {
      this.$htmlToPaper("printMe");
    }
  }
};

</script>
<style scoped>
img {
  object-fit: cover;
  height: 200px;
  max-width: 200px !important;
}

th {
  text-align: center !important;
}

@media print {
  th {
    color: red !important;
    text-align: center !important
  }
}

.ti-close {
  cursor: pointer;
}
</style>
