<template>
  <div class="row">
    <div class="col-lg-11 mt-5 mx-auto">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Enrollers</h4>
          <div class="single-table">
            <div class="table-responsive">
              <table class="table text-center">
                <thead class="text-uppercase bg-success">
                  <tr class="text-white">
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">email</th>
                    <th>Phone No</th>
                    <th scope="col">Training Program</th>
                    <th scope="col">Referral</th>
                    <th scope="col">Message</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(enroller, index) in enrollers" :key="index">
                    <th scope="row">{{ enroller.id }}</th>
                    <td>
                      {{ enroller.name }}
                    </td>
                    <td>{{ enroller.email }}</td>
                    <td>{{ enroller.phone }}</td>
                    <td>{{ enroller.training_program }}</td>
                    <td>{{ enroller.referral }}</td>
                    <td>{{ enroller.message }}</td>
                    <td><i @click="deleteEnroller(enroller.id)" class="ti-trash text-danger"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: "dashboard",
  computed: {
    enrollers() {
      return this.$store.getters["admin/getEnrollers"];
    }
  },

  methods: {
    deleteEnroller(enrollerId) {
      this.$store.commit("deleteEnroller", enrollerId);
    }
  },

  mounted() {
    this.$store.dispatch("admin/getEnrollers");

  }
};
</script>
