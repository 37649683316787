<template>
  <div>
    <div v-if="!isSubmitted" class="row">
      <div class="col-12 mt-5">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">Add Client</h4>
            <form>
              <div class="form-group">
                <label for="example-name-input" class="col-form-label">Name</label>
                <input v-model="customer.name" class="form-control" type="text" id="example-name-input">
              </div>
              <div class="form-group">
                <label class="col-form-label">State</label>
                <select v-model="customer.state" class="form-control">
                  <option v-for="(state, index) in states" :key="index">{{ state }}</option>
                </select>
              </div>
              <button @click.prevent="handleSubmit" class="btn btn-primary" type="submit">Submit form</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <BookingSummary @closeSummary="setToFalse" v-else />
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue";
export default {
  components: {
    Spinner
  },
  data() {
    return {
      customer: {
        email: "",
        start_date: "",
        // description: "",
        duration_type: null
      },

      states: ["Abia", "CRS", "Lagos", "River", "Niger"]
    };
  },

  computed: {
   
  },

  methods: {
    
  }
};
</script>
<style scoped>
.imagepreview {
  width: 300px;
  min-height: 100px;
  border: 2px solid #dddddd;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #eeeeee;
}

.imagepreview__image {
  display: none;
  width: 100%;
}

.btn {
  background-color: #24b99a;
  border-color: #24b99a;
  text-transform: uppercase;
  font-size: small;
}

.btn:hover {
  background-color: white;
  color: #24b99a;
}

.btn-success {
  margin: 79.565px auto;
}
</style>