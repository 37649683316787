<template>
  <div>
    <div v-if="!isSubmitted" class="row">
      <div class="col-12 mt-5">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">Get Quota</h4>
            <form>
              <div class="form-group">
                <label class="col-form-label">Select Clients</label>
                <select v-model="client" class="form-control">
                  <option v-for="(client, index) in clients" :key="index">{{ client.name }}</option>
                </select>
              </div>
              <button @click.prevent="handleSubmit" class="btn btn-primary mt-3" type="submit">Get Quota</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <BookingSummary @closeSummary="setToFalse" v-else />
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue";
export default {
  components: {
    Spinner
  },
  data() {
    return {
      client: null,
      clients: [{ name: "Mosi", state: "CRS" }, { name: "Pius", state: "Lagos" }, { name: "Anthoinette", state: "Rivers" }, { name: "Caleb", state: "Niger" }]
    };
  },

  computed: {
   
  },

  methods: {
    
  }
};
</script>
<style scoped>
.imagepreview {
  width: 300px;
  min-height: 100px;
  border: 2px solid #dddddd;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #eeeeee;
}

.imagepreview__image {
  display: none;
  width: 100%;
}

.btn {
  background-color: #24b99a;
  border-color: #24b99a;
  text-transform: uppercase;
  font-size: small;
}

.btn:hover {
  background-color: white;
  color: #24b99a;
}

.btn-success {
  margin: 79.565px auto;
}
</style>