<template>
  <div class="row">
    <div class="col-12 mt-5">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">All bookings</h4>
          <div class="single-table">
            <div class="table-responsive">
              <table class="table table-hover progress-table text-center">
                <thead class="text-uppercase">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Start date</th>
                    <th scope="col">End date</th>
                    <th scope="col">status</th>
                    <th scope="col">payment reference</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(customer, index) in registeredCustomers" :key="index">
                    <th scope="row">{{ customer.id }}</th>
                    <td>{{ customer.full_name }}</td>
                    <td>{{ customer.email }}</td>
                    <td>{{ customer.start_date | dateFilter }}</td>
                    <td>{{ customer.end_date | dateFilter }}</td>
                    <td>
                      <span v-if="customer.subscription_status==='Active'" class="status-p bg-success">active</span>
                      <span v-else class="status-p bg-primary">pending</span>
                    </td>
                    <td>{{ customer.payment_reference}}</td>
                    <td>
                      <ul class="d-flex justify-content-center">
                        <li><a href="#" class="text-danger"><i @click="deleteCustomer(customer.id)" class="ti-trash"></i></a></li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <strong class="card-title">All bookings</strong>
        </div>
        <div class="table-stats order-table ov-h">
          <table class="table">
            <thead>
              <tr>
                <th class="serial">#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Payment Reference</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(customer, index) in registeredCustomers" :key="index">
                <td class="serial">{{ customer.id }}</td>
                <td>
                  <span class="name">{{ customer.full_name }}</span>
                </td>
                <td>
                  <span class="product">{{ customer.email }}</span>
                </td>
                <td>
                  <span class="product">{{ customer.start_date | dateFilter }}</span>
                </td>
                <td>
                  <span class="product">{{ customer.end_date | dateFilter }}</span>
                </td>
                <td>
                  <span v-if="customer.subscription_status==='Active'" class="badge badge-complete">Active</span>
                  <span v-else class="badge badge-pending">Pending</span>
                </td>
                <td>
                  <span class="product">{{ customer.payment_reference}}</span>
                </td>
                <td>
                  <i @click="deleteCustomer(customer.id)" class="fa fa-trash"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  // middleware: ["check-auth", "auth"],
  computed: {
    registeredCustomers() {
      return this.$store.getters. ["hub/getHubBookings"];
    }
  },

  mounted() {
    this.$store.dispatch("hub/get_all_bookings");
  },

  methods: {
    deleteCustomer(customerId) {
      this.showQuestion().then((result) => {
        if (result.value) {
          this.$store.dispatch("delete_customer", customerId)
            .then((data) => {
              console.log(data);
              Swal.fire(
                "Deleted!",
                "Customer has been deleted.",
                "success"
              );
              this.$store.commit("DELETE_CUSTOMER", customerId);
            }).catch((e) => {
              console.log(e);
              this.showError("Customer delete unsuccessful");
            });
        }
      });
    }
  }
};
</script>
<style scoped>
.fa-trash {
  cursor: pointer;
}
</style>