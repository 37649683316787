<template>
  <div class="col-lg-10 mx-auto">
    <div class="card">
      <div class="card-header">
        <strong class="card-title">Edit User</strong>
      </div>
      <div class="card-body">
        <!-- Credit Card -->
        <div id="pay-invoice">
          <div class="card-body">
            <form action="#" method="post" novalidate="novalidate">
              <div class="row">
                <div class="col-lg-12">
                  <label for="x_card_code1" class="control-label mb-1">Name</label>
                  <div class="input-group">
                    <input v-model="staff.name" id="x_card_code1" name="x_card_code" type="text" class="form-control cc-cvc" value="" autocomplete="off">
                  </div>
                </div>
                <div class="col-lg-12">
                  <label for="x_card_code2" class="control-label mb-1">Email</label>
                  <div class="input-group">
                    <input v-model="staff.email" id="x_card_code2" name="x_card_code" type="email" class="form-control cc-cvc" value="" autocomplete="off">
                  </div>
                </div>
                <div class="col-lg-12">
                  <label for="x_card_code" class="control-label mb-1">Staff id</label>
                  <div class="input-group">
                    <input v-model="staff.id" id="x_card_code" name="x_card_code" type="text" class="form-control cc-cvc" value="" autocomplete="off">
                  </div>
                </div>
                <div class="col-lg-12">
                  <label for="x_card_code" class="control-label mb-1">Designation</label>
                  <div class="input-group">
                    <input v-model="staff.designation" id="x_card_code" name="x_card_code" type="text" class="form-control cc-cvc" value="" autocomplete="off">
                  </div>
                </div>
                <div class="col-lg-12">
                  <label for="x_card_code" class="control-label mb-1">Phone number</label>
                  <div class="input-group">
                    <input v-model="staff.phone" id="x_card_code" name="x_card_code" type="tel" class="form-control cc-cvc" value="" autocomplete="off">
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="cc-payment1" class="control-label mb-1">House address</label>
                    <input v-model="staff.address" id="cc-payment1" name="cc-desc" type="text" class="form-control" aria-required="true" aria-invalid="false" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="cc-payment2" class="control-label mb-1">Salary</label>
                    <input v-model="staff.salary" id="cc-payment2" name="cc-desc" type="number" class="form-control" aria-required="true" aria-invalid="false" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="cc-payment3" class="control-label mb-1">Account Number</label>
                    <input v-model="staff.account_number" id="cc-payment3" name="cc-desc" type="text" class="form-control" aria-required="true" aria-invalid="false" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button @click.prevent="editProfile" id="payment-button" type="submit" class="btn btn-lg btn-info btn-block mt-3">
                    <span id="payment-button-amount">Done</span>
                    <span id="payment-button-sending" style="display:none;">Sending…</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> <!-- .card -->
    <Spinner v-if="isLoading" />
  </div>
  <!--/.col-->
</template>
<script>
import _ from "lodash";
export default {
  data() {
    return {
      staff: {
        name: null,
        email: null,
        id: null,
        designation: null,
        salary: null,
        account_number: null,
        phone: null,
        address: null
      },
      isLoading: false
    }
  },

  computed: {
    single_staff() {
      return this.$store.getters["admin/getSingleStaff"];
    }
  },

  mounted() {
    if (_.isEmpty(this.single_staff)===false) {
      this.staff.id = this.single_staff.staff_id;
      this.staff.name = this.single_staff.name;
      this.staff.email = this.single_staff.email;
      this.staff.designation = this.single_staff.designation;
      this.staff.phone = this.single_staff.phone;
      this.staff.address = this.single_staff.house_address;
      this.staff.salary = this.single_staff.salary;
      this.staff.account_number = this.single_staff.account_number;
    }
  },

  methods: {
    editProfile() {
      this.$store.dispatch("admin/editProfile", {
          name: this.staff.name,
          email: this.staff.email,
          id: this.$route.params.id,
          salary: this.staff.salary,
          account_number: this.staff.account_number,
          staff_id: this.staff.id,
          designation: this.staff.designation,
          phone: this.staff.phone,
          house_address: this.staff.address
      })
      .then((data) => {
          this.isLoading = false;
          console.log("editprofile data: ", data.data);
          console.log("data edited");
          this.$swal({
            position: "center",
            icon: "success",
            title: data.data,
            showConfirmButton: false,
            timer: 1500
          })
          // this.staff.id = null;
          // this.staff.designation = null;
          // this.staff.phone = null;
          // this.staff.address = null;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$swal({
            position: "center",
            icon: "error",
            title: err,
            showConfirmButton: false,
            timer: 1500
          })
          console.log(err);
        });
    }
  }
}
</script>
<style scoped>
#payment-button-amount {
  font-size: 15px !important;
}
</style>