<template>
  <div>
    <div v-if="!showVoucherForm" class="row">
      <div class="col-md-6 mt-5 mb-3">
        <router-link to="/dashboard/finance/voucher/list">
          <div class="card">
            <div class="seo-fact sbg1">
              <div class="p-4 d-flex justify-content-between align-items-center">
                <div class="seofct-icon"><i class="ti-eye"></i></div>
                <h2>View vouchers</h2>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-6 mt-5 mb-3">
        <a @click.prevent="showVoucherForm = true" href="#">
          <div class="card">
            <div class="seo-fact sbg2">
              <div class="p-4 d-flex justify-content-between align-items-center">
                <div class="seofct-icon"><i class="ti-plus"></i></div>
                <h2>Generate voucher</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <VoucherForm @goToVoucherHome="closeForm" @closeVoucherForm="closeForm" v-if="showVoucherForm" />
  </div>
</template>
<script>
import VoucherForm from "@/components/Dashboard/VoucherForm.vue";
export default {
  components: {
    VoucherForm
  },
  data() {
    return {
      showVoucherForm: false
    };
  },

  methods: {
    closeForm() {
      this.showVoucherForm = false;
    }
  }
};
</script>