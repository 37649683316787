<template>
  <div class="row">
    <!-- table info start -->
    <div class="col-lg-11 mt-5 mx-auto">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Users</h4>
          <div class="single-table">
            <div class="table-responsive">
              <table class="table text-center">
                <thead class="text-uppercase bg-success">
                  <tr class="text-white">
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">email</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user.id">
                    <th scope="row">{{ user.id }}</th>
                    <td><router-link :to="'/dashboard/staff/' + user.id + '/onboard'">
                      {{ user.name }}
                    </router-link>
                    </td>
                    <td>{{ user.email }}</td>
                    <td><i @click="deleteUser(user.id)" class="ti-trash text-danger"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- table info end -->
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  computed: {
    users() {
      return this.$store.getters["admin/users"];
    }
  },

  mounted() {
    this.$store.dispatch("admin/getUsers").then(() => {
      console.log("users fetched successfuly on table");
      console.log("users or staff: ", this.users);
    });
  },

  methods: {
    deleteUser(userId) {
      this.showQuestion().then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteUser", userId).then((data) => {
            console.log(data);
            Swal.fire(
              "Deleted!",
              "User has been deleted.",
              "success"
            );
            this.$store.commit("deleteUser", userId);
          }).catch((e) => {
            console.log(e);
            this.showError("Post delete unsuccessful");
          });
        }
      });
    }
  }
};

</script>
<style scoped>
.fa-trash {
  cursor: pointer;
}
a {
  color: #212529;
}

a:hover {
  color: #28a745;
}
</style>



  

