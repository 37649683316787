<template>
  <div class="col-12 mt-5">
    <div class="card">
      <div class="card-body">
        <h4 class="header-title">Onboard User</h4>
        <form>
          <div class="form-group">
            <label for="example-id-input" class="col-form-label">Staff Id</label>
            <input v-model="staff.id" class="form-control" type="text" id="example-id-input">
          </div>
          <div class="form-group">
            <label for="example-designation-input" class="col-form-label">Designation</label>
            <input v-model="staff.designation" class="form-control" type="text" id="example-designation-input">
          </div>
          <!-- start profile upload -->
          <div class="form-group mt-4">
            <label class="form-submit btn btn-primary mb10">
              <input @change="preview('file-Input', 'image-Preview', '.imagepreview__image', '.imagepreview__default-text')" type="file" id="file-Input" />
              Insert Profile Image
            </label>
            <div class="imagepreview" id="image-Preview">
              <img src="" alt="Image Preview" class="imagepreview__image">
              <span class="imagepreview__default-text"><i class="pe-7s-photo"></i></span>
            </div>
          </div>
          <!-- end profile upload -->

          <!-- start signature upload -->
          <div class="form-group mt-4">
            <label class="form-submit btn btn-primary mb10">
              <input @change="preview('file-Input1', 'image-Preview1', '.imagepreview__image1', '.imagepreview__default-text1')" type="file" id="file-Input1" />
              Insert Signature Image
            </label>
            <div class="imagepreview1" id="image-Preview1">
              <img src="" alt="Image Preview" class="imagepreview__image1">
              <span class="imagepreview__default-text1"><i class="pe-7s-photo"></i></span>
            </div>
          </div>
          <!-- end signature upload -->
          
          <div class="form-group">
            <label for="example-phone-input" class="col-form-label">Phone</label>
            <input v-model="staff.phone" class="form-control" type="tel" id="example-phone-input">
          </div>
          <div class="form-group">
            <label for="example-address-input" class="col-form-label">Address</label>
            <input v-model="staff.address" class="form-control" type="text" id="example-address-input">
          </div>
          <button @click.prevent="submit" class="btn btn-primary" type="submit">Submit</button>
        </form>
      </div>
    </div> <!-- .card -->
    <Spinner v-if="isLoading" />
  </div>
  <!--/.col-->
</template>
<script>
export default {
  data() {
    return {
      staff: {
        id: null,
        designation: null,
        // salary: null,
        // account_number: null,
        phone: null,
        address: null
      },
      isLoading: false
    }
  },

  computed: {
    single_staff() {
      return this.$store.getters["admin/getSingleStaff"];
    }
  },

  methods: {
    preview(fileInput, imgPrev, imgPrev_img, img_default_text) {
      const imgFile = document.getElementById(fileInput);
      const previewContainer = document.getElementById(imgPrev);
      const previewImage = previewContainer.querySelector(imgPrev_img);
      const previewDefaultText = previewContainer.querySelector(img_default_text);
      const file = imgFile.files[0];
      const vm = this;
      if (file) {
        const reader = new FileReader();
        previewDefaultText.style.display = "none";
        previewImage.style.display = "block";
        reader.addEventListener("load", function() {
          previewImage.setAttribute("src", this.result);
          vm.$store.commit("setImgUrl", this.result);
          localStorage.setItem("imageUrl", this.result);
          console.log("result: ", this.result);
        });
        reader.readAsDataURL(file);
      } else {
        previewDefaultText.style.display = null;
        previewImage.style.display = null;
        previewImage.setAttribute("src", "");
      }
    },

    submit() {
      this.isLoading = true;
      const fd = new FormData();
      // const profileInput = document.querySelector("#file-Input");
      // const signInput = document.querySelector("#file-Input1");
      console.log("$route.params.id: ", this.$route.params.id)
      fd.append("userId", this.$route.params.id);
      fd.append("staffId", this.staff.id);
      fd.append("designation", this.staff.designation);
      fd.append("phone", this.staff.phone);
      fd.append("houseAddress", this.staff.address);
      let info = {
        userId: this.$route.params.id,
        staffId: this.staff.id,
        designation: this.staff.designation,
        phone: this.staff.phone,
        houseAddress: this.staff.address
      }
      // fd.append("profilePhoto", profileInput.files[0]);
      // fd.append("signature", signInput.files[0]);
      this.$store.dispatch("admin/onboard", info)
        .then((data) => {
          this.isLoading = false;
          console.log("onboard data: ", data.data);
          console.log("works");
          this.$swal({
            position: "center",
            icon: "success",
            title: data.data,
            showConfirmButton: false,
            timer: 1500
          })
          this.staff.id = null;
          this.staff.designation = null;
          this.staff.phone = null;
          this.staff.address = null;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$swal({
            position: "center",
            icon: "error",
            title: err.response.data,
            showConfirmButton: false,
            timer: 1500
          })
          console.log(err);
        });
    }
  }
}
</script>
<style scoped>
#payment-button-amount {
  font-size: 15px !important;
}

.imagepreview, .imagepreview1 {
  width: 100%;
  min-height: 100px;
  border: 2px solid #dddddd;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #eeeeee;
}

.imagepreview__image, .imagepreview__image1 {
  display: none;
  width: 300px;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.form-submit {
  position: relative;
  font-weight: 300;
  font-size: 15px;
  background-color: #24b99a !important;
  border-color: #24b99a !important;
}

.pe-7s-photo {
  font-size: 60px;
  color: #24b99a;
}
</style>