<template>
  <header class="header-area" id="header-area">
    <div class="dope-nav-container breakpoint-off dope-sticky">
      <div class="container">
        <div class="row">
          <!-- dope Menu -->
          <nav class="dope-navbar justify-content-between" id="dopeNav">
            <!-- Logo -->
            <router-link class="nav-brand" to="/">
              <img class="img-fluid" src="../assets/img/admin-logo.png" alt="">
            </router-link>
            <!-- Navbar Toggler -->
            <div class="dope-navbar-toggler">
              <span class="navbarToggler">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
            <!-- Menu -->
            <div class="dope-menu">
              <!-- close btn -->
              <div class="dopecloseIcon">
                <div class="cross-wrap">
                  <span class="top"></span>
                  <span class="bottom"></span>
                </div>
              </div>
              <!-- Nav Start -->
              <div class="dopenav">
                <ul id="nav">
                  <li>
                    <router-link to="/">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/about">About</router-link>
                  </li>
                  <li>
                    <a href="https://blog.bigstacktech.com">Blog</a>
                  </li>
                  <li>
                    <router-link to="/training">Training Programmes</router-link>
                  </li>
                  <li>
                    <router-link to="/our-hub">Our Hub</router-link>
                  </li>
                  <li v-if="$route.path == newpath">
                    <a href="#contact-section">Contact</a>
                  </li>
                  <li v-else>
                    <router-link to="/#contact-section">Contact</router-link>
                  </li>
                  <li v-if="isLoggedIn">
                    <router-link to="/our-hub">{{ userProfile.name }}</router-link>
                  </li>
                  <li v-if="!isLoggedIn">
                    <router-link to="/signin">Login</router-link>
                  </li>

                </ul>
              </div>
              <!-- Nav End -->
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import $ from "jquery";
import _ from "lodash";
export default {
  data() {
    return {
      newpath: "/",
      defaultOpt: {
        stickyNav: true
      },

      user: JSON.parse(localStorage.getItem("user")),
      showLogin: true,
      username: null
    }
  },

  computed: {
    userProfile() {
      return this.$store.getters["user/user"];
    },

    isLoggedIn() {
      let loginDeet;
      if (_.isEmpty(this.userProfile)) {
        loginDeet = false;
      } else {
        loginDeet = true;
      }
      return loginDeet;
    }
  },

  watch: {
    $route() {
      $('.navbarToggler').removeClass('active');
      $('.dope-menu').removeClass('menu-on');
      if (_.isEmpty(JSON.parse(localStorage.getItem("user")))===false) {
        this.showLogin = false;
        console.log("breaddd");
      } else {
        this.showLogin = true;
        // this.username = JSON.parse(localStorage.getItem("user")).name;
      }
    }
  },

  // beforeMount() {
  //   window.addEventListener("scroll", this.onScroll);
  // },
  mounted() {
    // get User
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return;
    }
    this.$store.dispatch("user/getUser", user.id)
      .then(()=> {
        console.log("isAuth: ", this.isLoggedIn);
      })
    // sticky
    const var_window = $(window);
    const navContainer = $('.dope-nav-container');
    if (this.defaultOpt.stickyNav === true) {
      var_window.on('scroll', function() {
        if (var_window.scrollTop() > 0) {
          navContainer.addClass('dope-sticky');
        } 
      });
    }
    console.log("route path: ", this.$route.path);
    if (this.$route.path !== this.newpath) {
      const theHeader = $('.dope-nav-container');
      theHeader.classList.add("dope-sticky");
    }
  },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.onScroll);
  // },

  methods: {
    onScroll() {
      console.log("scrolled")
      if (this.$route.path !== "/") {
        const theHeader = $('.dope-nav-container');
        const sticky = theHeader.offsetTop;
        // this.windowTop = e.target.documentElement.scrollTop;

        if (window.pageYOffset > sticky) {
          theHeader.classList.add("dope-sticky");
          // theHeader.classList.remove("header-scrolled");
        }
      }
      // if (this.$route.path === "/") {
      //   const theHeader = document.getElementById("header");
      //   const sticky = theHeader.offsetTop;
      //   if (window.pageYOffset > sticky) {
      //     this.imgUrl = "_nuxt/img/14ca28f.png";
      //   } else {
      //     this.imgUrl = "_nuxt/img/b415457.png";
      //   }
      // }
    }
  }
}
</script>