<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-12 mb-4">
        <button @click="goToOnboard" class="btn btn-primary btn-lg">Edit Profile</button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 mt-5 mb-3">
        <div class="card">
          <div class="seo-fact sbg3">
            <div class="p-4 d-flex justify-content-between align-items-center">
              <div class="seofct-icon"><i class="ti-user"></i></div>
              <div>
                <h2>{{ single_staff.name }}</h2>
                <p class="text-white">{{ single_staff.staff_id }}</p>
                <p class="text-white">{{ single_staff.designation }}</p>
                <p class="text-white">&#8358;{{ single_staff.salary }}</p>
                <p class="text-white">{{ single_staff.account_number }}</p>
                <p class="text-white">{{ single_staff.phone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 mt-5 mb-3">
        <div class="card">
          <div class="seo-fact sbg2">
            <div class="p-4 d-flex justify-content-between align-items-center">
              <div class="seofct-icon"><i class="ti-id-badge"></i></div>
              <div>
                <h2>Addresses</h2>
                <p class="text-white">{{ single_staff.house_address }}</p>
                <p class="text-white">{{ single_staff.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$_.isEmpty(single_staff) === false && JSON.parse(single_staff.salary_details).deductions.length > 0" class="row">
      <div class="col-lg-12 mt-5">
        <div class="card">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title">Deductions</h4>
              <div class="single-table">
                <div class="table-responsive">
                  <table class="table text-center">
                    <thead class="text-uppercase">
                      <tr>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(deduction, index) in JSON.parse(single_staff.salary_details).deductions" :key="index">
                        <th scope="row">&#8358;{{ deduction.amount }}</th>
                        <td>
                          {{ deduction.description }}
                        </td>
                        <td>{{ deduction.date | dateFilter }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$_.isEmpty(single_staff) === false && JSON.parse(single_staff.salary_details).additions.length > 0" class="row">
      <div class="col-lg-12 mt-5">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">Additions</h4>
            <div class="single-table">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead class="text-uppercase">
                    <tr>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(addition, index) in JSON.parse(single_staff.salary_details).additions" :key="index">
                      <th scope="row">&#8358;{{ addition.amount }}</th>
                      <td>
                        {{ addition.description }}
                      </td>
                      <td>{{ addition.date | dateFilter }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$_.isEmpty(single_staff)===false" class="row">
      <div class="col-lg-12 mt-5">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">Deduct from or increment to pay</h4>
            <form>
              <div class="row">
                <div class="col-lg-6">
                  <label for="example-number-input" class="col-form-label">Amount</label>
                  <input v-model="amount" class="form-control" type="number" id="example-number-input">
                </div>
                <div class="col-lg-3">
                  <div class="form-check-inline form-check">
                    <label for="inline-radio1" class="form-check-label ">
                      <input v-model="deduct" type="radio" id="inline-radio1" name="inline-radios" value="deduct" class="form-check-input">Deduct
                    </label>
                  </div>
                </div>
                <div class="col-lg-3">
                        <div class="form-check-inline form-check">
                          <label for="inline-radio2" class="form-check-label">
                            <input v-model="increment" type="radio" id="inline-radio2" name="inline-radios" value="increment" class="form-check-input">Increment
                          </label>
                        </div>
                      </div>
              </div>
              <div class="form-group">
                <label for="example-desc-input" class="col-form-label">Description</label>
                <input v-model="description" class="form-control" type="text" id="example-desc-input">
              </div>
              <button @click.prevent="submit" class="btn btn-primary" type="submit">Submit</button>
            </form>
          </div>
          <!-- <div class="card-header">
              <strong class="card-title">Deduct from or increment to pay</strong>
            </div>
            <div class="card-body">
              <div id="pay-invoice">
                <div class="card-body">
                  <form action="#" method="post" novalidate="novalidate">
                    <div class="row">
                      <div class="col-lg-6">
                        <label for="x_card_code" class="control-label mb-1">Amount</label>
                        <div class="input-group">
                          <input v-model="amount" id="x_card_code" name="x_card_code" type="number" class="form-control cc-cvc" value="" autocomplete="off">
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-check-inline form-check">
                          <label for="inline-radio1" class="form-check-label ">
                            <input v-model="deduct" type="radio" id="inline-radio1" name="inline-radios" value="deduct" class="form-check-input">Deduct
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-check-inline form-check">
                          <label for="inline-radio2" class="form-check-label">
                            <input v-model="increment" type="radio" id="inline-radio2" name="inline-radios" value="increment" class="form-check-input">Increment
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="cc-payment" class="control-label mb-1">Description</label>
                          <textarea v-model="description" id="cc-payment" name="cc-desc" type="text" class="form-control" aria-required="true" aria-invalid="false"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button @click.prevent="submit" id="payment-button" type="submit" class="btn btn-lg btn-info btn-block mt-3">
                          <span id="payment-button-amount">Done</span>
                          <span id="payment-button-sending" style="display:none;">Sending…</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> -->
        </div> <!-- .card -->
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
// import store from '@/store/index.js'
export default {
  data() {
    return {
      amount: null,
      deduct: null,
      increment: null,
      description: null,
      isLoading: false
    }
  },
  computed: {
    single_staff() {
      return this.$store.getters["admin/getSingleStaff"];
    }
    // salary_details() {
    //   return JSON.parse(this.single_staff.salary_details);
    // }
  },

  methods: {
    submit() {
      this.isLoading = true;
      console.log("amount deducted or incremented: ", { amount: this.amount, deduct: this.deduct, increment: this.increment });
      let payslipDeets = {
        staffId: this.single_staff.staff_id,
        isDeduction: this.deduct === null ? false : true,
        detail: {
          amount: +this.amount,
          description: this.description,
          date: new Date()
        }
      }
      this.$store.dispatch("admin/modifyPayslip", payslipDeets)
        .then((data) => {
          this.isLoading = false;
          if (data) {
            this.$swal.fire({
              position: "center",
              icon: "success",
              title: data.data,
              showConfirmButton: false,
              timer: 1500
            });
            this.resetValues();
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
          this.resetValues();
          this.$swal.fire({
            position: "center",
            icon: "error",
            title: "Error",
            showConfirmButton: false,
            timer: 1500
          });;
        })
    },

    resetValues() {
      this.amount = null;
      this.description = null;
      this.increment = null;
      this.deduct = null;
    },

    goToOnboard() {
      this.$router.push('/dashboard/staff/' + this.$route.params.id + '/edit')
    },
    getStaff() {
      this.isLoading = true;
      this.$store.dispatch("admin/getStaff", this.$route.params.id)
        .then((data) => {
          this.isLoading = false;
          console.log("staff data: ", data.data);
          console.log("data obj: ", data.data[0]);
          if (data.data[0] !== undefined) {
            this.$store.commit("admin/SET_SINGLE_STAFF", data.data[0]);
          } else {
            this.$store.commit("admin/SET_SINGLE_STAFF", {})
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    }
  },

  created() {
    console.log("created")
    this.getStaff();
  }
};
</script>
<style scoped>
.form-check {
  margin-top: 1.6rem !important;
}
</style>