<template>
  <div class="row">
    <div class="col-12 mt-5">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Active bookings</h4>
          <div class="single-table">
            <div class="table-responsive">
              <table class="table table-hover progress-table text-center">
                <thead class="text-uppercase">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Start date</th>
                    <th scope="col">End date</th>
                    <th scope="col">payment reference</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(customer, index) in activeCustomers" :key="index">
                    <th scope="row">{{ customer.id }}</th>
                    <td>{{ customer.full_name }}</td>
                    <td>{{ customer.email }}</td>
                    <td>{{ customer.start_date | dateFilter }}</td>
                    <td>{{ customer.end_date | dateFilter }}</td>
                    <td>{{ customer.payment_reference}}</td>
                    <td>
                      <ul class="d-flex justify-content-center">
                        <li><a href="#" class="text-danger"><i @click="deleteCustomer(customer.id)" class="ti-trash"></i></a></li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  layout: "dashboard",
  middleware: ["check-auth", "auth"],
  computed: {
    activeCustomers() {
      return this.$store.getters.["hub/getActiveBookings"];
    }
  },

  mounted() {
    this.$store.dispatch("hub/get_active_bookings");
  },

  methods: {
    deleteCustomer(customerId) {
      this.showQuestion().then((result) => {
        if (result.value) {
          this.$store.dispatch("delete_customer", customerId)
            .then((data) => {
              console.log(data);
            Swal.fire(
              "Deleted!",
              "Booking has been deleted.",
              "success"
            );
            this.$store.commit("DELETE_CUSTOMER", customerId);
          }).catch((e) => {
            console.log(e);
            this.showError("Booking delete unsuccessful");
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.fa-trash {
  cursor: pointer;
}
</style>
