<template>
  <div class="row">
    <!-- table info start -->
    <div class="col-lg-11 mt-5 mx-auto">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Customers</h4>
          <div class="single-table">
            <div class="table-responsive">
              <table class="table text-center">
                <thead class="text-uppercase bg-success">
                  <tr class="text-white">
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">email</th>
                    <th scope="col">message</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(contact, index) in contacts" :key="index">
                    <th scope="row">{{ contact.id }}</th>
                    <td>
                        {{ contact.name }}
                    </td>
                    <td>{{ contact.email }}</td>
                    <td>{{ contact.message }}</td>
                    <td><i @click="deleteContact(contact.id)" class="ti-trash text-danger"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- table info end -->
  </div>
  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <strong class="card-title">Customers</strong>
        </div>
        <div class="table-stats order-table ov-h">
          <table class="table">
            <thead>
              <tr>
                <th class="serial">#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(contact, index) in contacts" :key="index">
                <td class="serial">{{ index+1 }}</td>
                <td>
                  <span class="name">{{ contact.name }}</span>
                </td>
                <td>
                  <span class="product">{{ contact.email }}</span>
                </td>
                <td>
                  <span>{{ contact.message }}</span>
                </td>
                <td>
                  <span @click="deleteContact(contact.id)">
                    <i class="fa fa-trash"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  layout: "dashboard",
  computed: {
    contacts() {
      return this.$store.getters["admin/getContacts"];
    }
  },

  methods: {
    deleteContact(contactId) {
      this.$store.commit("deleteCustomer", contactId);
    }
  },

  mounted() {
    this.$store.dispatch("admin/getContacts");
  }
};
</script>
