<template>
  <div class="row">
    <!-- table info start -->
    <div class="col-lg-11 mt-5 mx-auto">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Staff</h4>
          <div class="single-table">
            <div class="table-responsive">
              <table class="table text-center">
                <thead class="text-uppercase bg-success">
                  <tr class="text-white">
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">email</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(singleStaff, index) in staff" :key="index">
                    <th scope="row">{{ singleStaff.id }}</th>
                    <td>
                      <router-link :to="'/dashboard/staff/' + singleStaff.id">
                        {{ singleStaff.name }}
                      </router-link>
                    </td>
                    <td>{{ singleStaff.email }}</td>
                    <td><i @click="deleteUser(singleStaff.id)" class="ti-trash text-danger"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- table info end -->
  </div>
  <!-- <div class="row">
    <div class="col-lg-12">
      <div  class="card">
        <div class="card-header">
          <strong class="card-title">Staff</strong>
        </div>
        <div class="table-stats order-table ov-h">
          <table class="table">
            <thead>
              <tr>
                <th class="serial">#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(singleStaff, index) in staff" :key="index">
                <td class="serial">{{ singleStaff.id }}.</td>
                <td>
                  <router-link :to="'/dashboard/staff/' + singleStaff.id"><span class="name">{{ singleStaff.name }}</span></router-link>
                </td>
                <td>
                  <span class="product">{{ singleStaff.email }}</span>
                </td>
                <td>
                  <i @click="deleteUser(singleStaff.id)" class="fa fa-trash"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
  computed: {
    staff() {
      return this.$store.getters["admin/getStaff"];
    }
  },

  mounted() {
    this.$store.dispatch("admin/getUsers").then(() => {
      console.log("users fetched successfuly");
    });
  }
};
</script>
<style scoped>
.fa-trash {
  cursor: pointer;
}

a {
  color: #212529;
}

a:hover {
  color: #28a745;
}
</style>